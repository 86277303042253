import React from 'react';
import FormSelect from "../FormSelect/FormSelect";
import MenuItem from '@mui/material/MenuItem';
import {Counties} from "../../const/counties";

const CountySelect = ({
                        name,
                        label,
                        control,
                        value,
                        errors,
                        InputProps,
                        abroadLabel
                      }) => {
    return (
        <FormSelect name={name} label={label} control={control} value={value} errors={errors} InputProps={InputProps}>
            <MenuItem disabled value="">
                <em>Romania</em>
            </MenuItem>
            {
                Counties.map((item,idx) => {
                    return (<MenuItem key={idx} value={item}>{item}</MenuItem>);
                })
            }
            <MenuItem disabled value="">
                <em>{abroadLabel}</em>
            </MenuItem>
            <MenuItem value="Strainatate">{abroadLabel}</MenuItem>
        </FormSelect>
    );
}

export default CountySelect;