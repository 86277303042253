import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useStateMachine} from "little-state-machine";
import clearAction from "../ClearAction";
import Button from "@mui/material/Button";
import {Grid} from "@mui/material";
import {FormSteps} from "../../../const";
import {useTranslation} from "react-i18next";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import AntiqueBackdrop from "../../AntiqueBackdrop/AntiqueBackdrop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from '@mui/icons-material/Send';
import {REST_API_ENDPOINT} from "../../../const";

const Step3 = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const { handleSubmit } = useForm();
    const { state, actions } = useStateMachine({clearAction});

    const [ messages, setMessages ] = useState({ errors : [], messages : [], sent : false, loading:false});
    const { sent, loading } = messages;


    const onError = (responseData) => {
        let errors = [];
        if(typeof responseData?.message !== 'undefined') errors.push(responseData.message);
        else{
            if(Array.isArray(responseData.errors))
            {
                responseData.errors.map(item => {
                    errors.push(item.toString());
                    return true;
                })
            }
            else errors = ["Unknown error"];
        }
        setMessages({errors : errors, messages: [], sent: false, loading:false});
    };

    const onSubmit = () => {

        setMessages({errors : [], messages: [], sent: false, loading: true});



        const copiedObject = JSON.parse(JSON.stringify(state));
        copiedObject.items.map((item,idx) => {
            copiedObject.items[idx].image.content = item.image.content.substr(item.image.content.indexOf('base64') + 7);
            return true;
        });

        axios.post(REST_API_ENDPOINT+`/quote`,  copiedObject )
            .then(res => {
                actions.clearAction();
                setMessages({errors : [], messages: [res.data], sent: true, loading: false});
                navigate("../"+t(FormSteps.Step4), {replace:true, state: {result : [res.data]}});

            }).catch(e=>{
                onError(e.response.data);
            })
    };

    const step2Completed = () =>{
        return typeof state.firstName !== 'undefined' && state.firstName.length > 0;
    }

    const goBack = () => {
        navigate("../"+t(FormSteps.Step2));
    }

    useEffect(() => {
        if(!step2Completed() && !sent) navigate("../");
    });
    const getFormLabelTranslationFor = (name) => 'forms.quoteForm.labels.'+name;

    return (
        <form>
            <Grid container spacing={2} margin={2}>
                <Grid item xs={12}>
                    <AntiqueBackdrop loading={loading} color={"inherit"} text={"Sending form"}/>
                </Grid>

                <Grid item xs={12}>{messages.errors.length ?
                (
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        <ul>
                        {messages.errors.map((error, idx) => {
                            return <li key={idx}>{error}</li>
                        })}
                        </ul>
                    </Alert>
                ) :
                <></>
                }
                </Grid>



            {sent || Object.keys(state).length === 0   ? '' :
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>{t(getFormLabelTranslationFor('firstName'))}</Grid><Grid item xs={6}>{state.firstName}</Grid>
                        <Grid item xs={6}>{t(getFormLabelTranslationFor('lastName'))}</Grid><Grid item xs={6}>{state.lastName}</Grid>
                        <Grid item xs={6}>{t(getFormLabelTranslationFor('email'))}</Grid><Grid item xs={6}>{state.email}</Grid>
                        <Grid item xs={6}>{t(getFormLabelTranslationFor('phone'))}</Grid><Grid item xs={6}>{state.phone}</Grid>
                        <Grid item xs={6}>{t(getFormLabelTranslationFor('details'))}</Grid><Grid item xs={6}>{state.details}</Grid>

                        {
                            state.items.map((item,idx) => (


                                <Grid container item xs={12} key={idx} spacing={2}>
                                        <Grid item xs={6}>{t(getFormLabelTranslationFor('item_name'))}</Grid><Grid item xs={6}>{item.name}</Grid>
                                        <Grid item xs={6}>{t(getFormLabelTranslationFor('item_width'))}</Grid><Grid item xs={6}>{item.width}</Grid>
                                        <Grid item xs={6}>{t(getFormLabelTranslationFor('item_height'))}</Grid><Grid item xs={6}>{item.height}</Grid>
                                        <Grid item xs={6}>{t(getFormLabelTranslationFor('item_file_name'))}</Grid><Grid item xs={6}>{item.image.fileData.name}</Grid>
                                        <Grid item xs={6}>{t(getFormLabelTranslationFor('item_image'))}</Grid><Grid item xs={6}><img alt="" height={100} src={item.image.content}/></Grid>
                                </Grid>

                            ))
                        }

                    </Grid>
                    {loading ? '' :
                        <Grid item xs={12}>
                            <Button startIcon={<ArrowBackIcon />} onClick={goBack} variant={"outlined"}>
                                {t('back')}
                            </Button>
                            <Button startIcon={<SendIcon />} onClick={handleSubmit(onSubmit, onError)} variant={"contained"}>
                                {t('sendForm')}
                            </Button>
                        </Grid>
                    }
                </Grid>
                }

            </Grid>
        </form>

    );
}

export default Step3;