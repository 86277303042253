import React, {useContext} from "react";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "./../UpdateAction";
import {FormInput} from "../../FormInput/FormInput";
import {FormTextArea} from "../../FormTextArea/FormTextArea";
import Button from "@mui/material/Button";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FormSteps} from "../../../const";
import CountySelect from "../../CountySelect/CountySelect";
import {Counties} from "../../../const/counties";
import {Grid} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import {UserContext} from "../../../contexts/UserContext";

const schema = yup.object({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.number().required(),
    county: yup.mixed().oneOf([...Counties, "Strainatate"]).required(),
    details: yup.string().min(10,'at least 10 chars').max(500,'max').required(),
}).required();

const Step1 = () => {

    const navigate = useNavigate();
    const { actions, state } = useStateMachine({ updateAction });
    const {t} = useTranslation();

    const authUser = useContext(UserContext)

    const defaultValues = {
        firstName: authUser?.FamilyName? authUser.FamilyName : "",
        lastName: authUser?.GivenName? authUser.GivenName : "",
        email: authUser?.Email? authUser.Email : "",
        phone: authUser?.PhoneNumber? authUser.PhoneNumber : "",
        details : "",
        county: ""
    };


    const populateDefaultValues = () =>{
        const newValues = {...defaultValues};
        Object.keys(newValues).map((key) => {
            if(typeof state[key] !== 'undefined') {
                newValues[key] = state[key];
            }
            return true;
        })
        return newValues;
    }

    const { handleSubmit, control, formState:{ errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: populateDefaultValues()
    });

    const onReset = () => {
        reset(defaultValues)
    }

    const onSubmit = data => {
        actions.updateAction(data);
        navigate("./"+t(FormSteps.Step2));
    };

    const getFormLabelTranslationFor = (name) => 'forms.quoteForm.labels.'+name;
    const getFormErrorTranslationFor = (name) => 'forms.quoteForm.errors.'+name;

    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
            <Grid container spacing={2} margin={2}>
            <Grid item xs={12}>
                <FormInput name={"firstName"}
                           control={control}
                           label={t(getFormLabelTranslationFor("firstName"))}
                           errors={errors}
                           InputProps = {{
                               helperText : errors?.firstName?.message ? t(getFormErrorTranslationFor("firstName")) : ''
                           }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput name={"lastName"}
                           control={control}
                           label={t(getFormLabelTranslationFor("lastName"))}
                           errors={errors}
                           InputProps = {{
                               helperText : errors?.lastName?.message ? t(getFormErrorTranslationFor("lastName")) : ''
                           }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput name={"email"}
                           control={control}
                           label={t(getFormLabelTranslationFor("email"))}
                           errors={errors}
                           InputProps = {{
                               helperText : errors?.email?.message ? t(getFormErrorTranslationFor("email")) : ''
                           }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput name={"phone"}
                           control={control}
                           label={t(getFormLabelTranslationFor("phone"))}
                           errors={errors}
                           type={"number"}
                           InputProps = {{
                               helperText : errors?.phone?.message ? t(getFormErrorTranslationFor("phone")) : ''
                           }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextArea name={"details"}
                              control={control}
                              label={t(getFormLabelTranslationFor("details"))}
                              errors={errors}
                              InputProps = {{
                                  helperText : errors?.details?.message ? t(getFormErrorTranslationFor("details")) : ''
                              }}
                />
            </Grid>
            <Grid item xs={12}>
                <CountySelect name={"county"}
                              control={control}
                              label={t(getFormLabelTranslationFor("location"))}
                              abroadLabel={t(getFormLabelTranslationFor("abroad"))}
                              errors={errors}
                              InputProps = {{
                                  helperText : errors?.county?.message ? t(getFormErrorTranslationFor("location")) : ''
                              }}

                />
            </Grid>
            <Grid item xs={12}>
                <Button startIcon={<DeleteIcon />} onClick={onReset} variant={"outlined"}>
                    {t('reset')}
                </Button>
                <Button startIcon={<ArrowForwardIcon />} onClick={handleSubmit(onSubmit)} variant={"contained"}>
                    {t('next')}
                </Button>
            </Grid>
            </Grid>
        </form>
    );
};

export default Step1;