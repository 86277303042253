import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {useStateMachine} from "little-state-machine";
import updateAction from "../UpdateAction";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FormItem} from "../../FormItem/FormItem";
import {FormSteps} from "../../../const";
import {useTranslation} from "react-i18next";
import {Collapse, Grid} from "@mui/material";
import * as Yup from "yup";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {TransitionGroup} from "react-transition-group";
import Box from "@mui/material/Box";

const validationSchema = Yup.object().shape({
    items: Yup.array().of(
        Yup.object().shape({
            width: Yup.number().required('Width is required'),
            height: Yup.number().required('Height is required'),
            name: Yup.string().required('A name is required'),
            image : Yup.object().shape({
                fileData : Yup.object().shape({
                    size : Yup.number().min(100).max(150000, 'File size exceeded maximum value').required(),
                    name : Yup.string().required('File is required'),
                    type : Yup.string().required('File type is required')
                }),
                content :  Yup.string().required('image is required'),
                index :  Yup.string().required('image index is required')
            })
        })
    )
});


const Step2 = () => {

    const defaultItem = {
            width : '',
            height : '',
            name : '',
            image : {
                content : '',
                fileData : {
                    size : 0,
                    name : '',
                    type : ''
                },
                index : ''
            }
        };
    const navigate = useNavigate();
    const {t} = useTranslation();

    const { actions, state } = useStateMachine({ updateAction });



    const populateDefaultValues = () =>{
        let itemsCount = state.items?.length;
        if(itemsCount === undefined || itemsCount === 0) {
            return { items : [defaultItem]};
        }
        let newValues = [];
        state.items.map((item) => {
            newValues.push(item);
            return true;
        })
        return { items : newValues};
    }


    const { handleSubmit, control, formState:{ errors }, reset, getValues } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: populateDefaultValues()
    });
    const { fields, append,remove } = useFieldArray({ name: 'items', control });

    const step1Completed = () =>{
        return typeof state.firstName !== 'undefined' && state.firstName.length > 0;
    }

    const onSubmit = (data) => {

        actions.updateAction(data);
        navigate("../"+t(FormSteps.Step3));
    };

    const goBack = () => {
        navigate("../");
    }

    const onReset = () => {
        reset({ items : [defaultItem]});
    }

    const addNewItem = (event ) => {
        append(defaultItem);
        event.preventDefault();
    }

    const removeItem = (index) => {
        remove(index);
    }


    useEffect(() => {
        if(!step1Completed()) navigate("../");
    });


    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
            <Grid container >
                <TransitionGroup>
                    {fields.map((item, index) =>{
                        return (
                            <Collapse key={item.id}>
                                <Grid container item xs={12} md={12} key={index} >

                                    <Grid item xs={12} md={10} lg={10}>
                                        <Box sx={{ p: 2 }}>
                                        <FormItem
                                            control={control}
                                            index={index}
                                            errors={errors?.items?.[index]}
                                            name={item.id}
                                            getValues={getValues}
                                        />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={2} lg={2}>
                                        <Box sx={{ p: 2 }}>
                                        {
                                            fields.length > 1 ?
                                                <Button onClick={()=>removeItem(index)} startIcon={<RemoveIcon />}>{t('removerow')}</Button>
                                                :
                                                ''
                                        }
                                        </Box>
                                    </Grid>

                                </Grid>

                            </Collapse>
                        );
                    })}
                </TransitionGroup>
                <Grid item xs={12}>
                    <Button startIcon={<AddIcon />} onClick={addNewItem}>{t('addnewrow')}</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button startIcon={<ArrowBackIcon />} onClick={goBack} variant={"outlined"}>
                        {t('back')}
                    </Button>
                    <Button onClick={onReset} startIcon={<DeleteIcon />} variant={"outlined"}>
                        {t('reset')}
                    </Button>
                    <Button type="submit" startIcon={<ArrowForwardIcon />} onClick={handleSubmit(onSubmit)} variant={"contained"}>
                        {t('next')}
                    </Button>
                </Grid>

            </Grid>
        </form>
    )


}

export default Step2;