import React from "react";
import { Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import ImageFormItem from "../ImageFormItem/ImageFormItem";

export function FormItem (props)
{
    const {  control, errors, index, getValues  } = props;
    const {t} = useTranslation();

    const getFormLabelTranslationFor = (name) => 'forms.quoteForm.labels.'+name;
    const getFormErrorTranslationFor = (name) => 'forms.quoteForm.errors.'+name;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={2} md={2}>{t('item')} {index+1}</Grid>
            <Grid item xs={12} lg={2} md={2}>
            <Controller
                name={`items[${index}].name`}
                control={control}
                render={({field: {onChange, value}}) => (
                    <>
                        <TextField helperText = {errors && errors?.name ? t(getFormErrorTranslationFor("item_name")) : ''} onChange={onChange} value={value} label={t(getFormLabelTranslationFor("item_name"))} name={`items.${index}.name`} error={!!(errors && errors?.name)}/>
                    </>


                )}
            />
            </Grid>
            <Grid item xs={12} lg={2} md={2}>
            <Controller
                name={`items[${index}].width`}
                control={control}
                render={({field: {onChange, value}}) => (
                    <>
                        <TextField type={"number"} helperText = {errors && errors?.width ? t(getFormErrorTranslationFor("item_width")) : ''} onChange={onChange} value={value} label={t(getFormLabelTranslationFor("item_width"))} name={`items.${index}.width`} error={!!(errors && errors?.width)}/>
                    </>


                )}
            />
            </Grid>
            <Grid item xs={12} lg={2} md={2}>
            <Controller
                name={`items[${index}].height`}
                control={control}
                render={({field: {onChange, value}}) => (
                    <>
                        <TextField type={"number"} helperText = {errors && errors?.height ? t(getFormErrorTranslationFor("item_height")) : ''} onChange={onChange} value={value} label={t(getFormLabelTranslationFor("item_height"))} name={`items.${index}.height`} error={!!(errors && errors?.height)}/>
                    </>

                )}
            />
            </Grid>
            <Grid item xs={12} lg={4} md={4}>
            <ImageFormItem control={control} errors={errors} getValues={getValues} index={index} name={`items[${index}].image`} />
            </Grid>

        </Grid>
    );
}