import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {CircularProgress} from "@mui/material";
import {Controller} from "react-hook-form";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

const ImageFormItem = (props) =>
{
    const {  control, errors, index, name, getValues  } = props;
    const {t} = useTranslation();
    const fileUpload = React.createRef();


    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(getValues(name))
    const showFileUpload = () => {
        fileUpload.current.click();
    }

    const clearImage = () => {
        setValue({ content : '', fileData : {name : '', size: 0, type : ''}, index: index})
    }

    function handleFile(file) {

        const local = { content : '', fileData : {name : '', size: 0, type : ''}, index: index};
        if (!file.type.startsWith('image/')){ return local; }



        if(file.size > 150000) { return local; }

        setLoading(true)
        local.fileData.size = file.size;
        local.fileData.name = file.name;
        local.fileData.type = file.type;

        const reader = new FileReader();
        reader.onload = (function (e) {
            local.content = e.target.result
            setValue(local)
            setLoading(false)
        });
        reader.readAsDataURL(file);
        return local;
    }

    const getFormLabelTranslationFor = (name) => 'forms.quoteForm.labels.'+name;
    const getFormErrorTranslationFor = (name) => 'forms.quoteForm.errors.'+name;


    return (
        loading ?
            <CircularProgress/>
            :
        <>
            {errors ?
                <div>
                <div className="invalid-feedback">{errors?.image?.fileData?.name?t(getFormErrorTranslationFor("item_image_name")):''}</div>
                <div className="invalid-feedback">{errors?.image?.fileData?.size?t(getFormErrorTranslationFor("item_image_size")):''}</div>
                </div>
                :   ''
            }
            <Controller
                name={name}
                control={control}
                render={({field: {onChange}}) => (
                    <>
                        <input ref={fileUpload} type="file" accept="image/*" onChange={(evt)=>onChange(handleFile(evt.target.files[0]))} name={name} style={{display:'none'}}/>
                    </>

                )}
            />


            {value.content ?
                <>
                    {value.fileData.name}
                    <Avatar src={value.content}/>
                    <Button onClick={()=>clearImage()}>{t(getFormLabelTranslationFor("change_image"))}</Button>
                </>
                :
                <Button onClick={()=>showFileUpload()}>{t(getFormLabelTranslationFor("select_image"))}</Button>
            }
        </>

    );

}

export default ImageFormItem;